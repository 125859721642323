import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';

// 创建出库单
export function createDeliveryBill(data){
    return request('/storeDelivery/createDeliveryBill.do',"POST",Object.assign({userId:SystemUtils.loginUser.id,user:SystemUtils.loginUser.name},data))
}
// 查询任务单
export function getProjectStage(data){
    return request('/storeDelivery/getProjectStage.do',"POST",data)
}
// 用任务单查零件
export function getDeliveryItem(data){
    return request('/storeDelivery/getDeliveryItem.do',"POST",Object.assign({userId:SystemUtils.loginUser.id},data))
}
// 保存出库单
export function doSaveDeliveryItem(data){
    return request('/storeDelivery/doSaveDeliveryItem.do',"POST",Object.assign({userId:SystemUtils.loginUser.id,user:SystemUtils.loginUser.name,userAccount:SystemUtils.loginUser.account},data))
}
