<template >
  <ion-page>
    <Header type="0" title="创建出库单"> </Header>

    <ion-content
      scroll-y="true"
      class="content"
      :class="{ 'scan-container': isOpenScan }"
      id="ProjectMainProjectListOut"
    >
      <div class="root">
        <div class="item">
          <div class="left"><span class="required">*</span>任务单号</div>
          <div class="right">
            <ion-input
              :disabled="true"
              @click="getTaskCode"
              v-model="queryParam.stageCode"
              placeholder="请输入任务单号"
            ></ion-input>
          </div>
        </div>
        <div class="item">
          <div class="left"><span class="required">*</span>申请人</div>
          <div class="right">
            <ion-input
              :disabled="true"
              @click="getSearchPerson"
              v-model="queryParam.applyPerson"
              placeholder="请输入申请人姓名"
            ></ion-input>
          </div>
        </div>
      </div>
      <div class="root">
        <div class="item">
          <div class="left">零件名称</div>
          <div class="right">
            <ion-input v-model="value" placeholder="请输入零件名称"></ion-input>
            <ion-button class="selectBtn" @click="searchPart">查询</ion-button>
          </div>
        </div>
      </div>
      <!-- 无数据   -->
      <div class="no_data" v-if="tableList.length == 0 && !islist">
        <img
          class="no_data_img no_data_top"
          src="@/assets/images/notDate/notSearch.png"
          alt
        />
        <p class="no_data_text">未查询到信息</p>
      </div>

      <parts-card
        v-for="(item, index) in tableList"
        :key="index"
        :part="item"
      ></parts-card>
      <CardLoading
        v-if="tableList.length <= 0 && islist"
        lodingType="1"
      ></CardLoading>
      <qrScanner v-if="isOpenScan" @closeScan="closeScan"></qrScanner>
    </ion-content>
    <person-choose @chooseCode="searchPerson"></person-choose>
    <search-task-code-out @chooseCode="chooseCode"></search-task-code-out>
    <ion-footer style="background: #fff">
      <div class="canvars-style" style="">
       <span class="van-span" v-for="item in list">
          <van-tag
          plain
          v-if="item.checked"
          closeable
          size="medium"
          type="primary"
          @close="close(item)"
        >
          {{item.partCode}}
        </van-tag>
       </span>
      </div>
      <ion-button expand="block" @click="commit">保存</ion-button>
    </ion-footer>
  </ion-page>
</template>
<script>
import {
  alertController,
  ionAlert,
  IonSelect,
  IonSelectOption,
  menuController,
  loadingController,
} from "@ionic/vue";
import Header from "@/components/Header.vue";
import uploadImg from "@/components/getPhoto";
import { qrCode } from "ionicons/icons";
import qrScanner from "@/components/cordova/qrScanner.vue";
import {
  createDeliveryBill,
  getDeliveryItem,
  doSaveDeliveryItem,
} from "@/api/storeManagement/createOutWarehouse";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Utils from "@/utils/Utils.ts";
import searchTaskCodeOut from "./searchTaskCodeOut.vue";
import partsCard from "./partsCard.vue";
import personChoose from "./personChoose.vue";
export default {
  components: {
    IonSelect,
    IonSelectOption,
    Header,
    uploadImg,
    CardLoading,
    ionAlert,
    qrScanner,
    searchTaskCodeOut,
    partsCard,
    personChoose,
  },
  data() {
    return {
      qrCode,
      tableList: [],
      islist: false, // isloading
      isOpenRef: false, // 确认框
      light: false,
      isOpenScan: false,
      queryParam: {
        stageCode: "",
        applyPerson: "",
        applyPersonId: "",
        phone: "",
        id: "",
        datas: [],
      },
      value: "",
      list: [],
    };
  },

  mounted() {
    this.createDeliveryBill();
  },
  methods: {
    createDeliveryBill() {
      this.islist = true;
      createDeliveryBill().then((res) => {
        this.islist = false;
        if (!res.data.isError) {
          this.queryParam.id = res.data.data.resultData.id;
        }
      });
    },
    transfer(type) {
      console.log("img");
    },
    getSearchPerson() {
      menuController.enable(true, "searchPerson");
      menuController.open("searchPerson");
    },
    getTaskCode() {
      menuController.enable(true, "searchTaskOut");
      menuController.open("searchTaskOut");
    },
    //选择申请人
    searchPerson(val) {
      this.queryParam.applyPersonId = val.id;
      this.queryParam.applyPerson = val.nameCN;
      this.queryParam.phone = val.telephone;
    },
    openQrScanner() {
      this.isOpenScan = true;
    },
    async commit() {
      if (!this.queryParam.id) {
        Utils.presentToastTopWarning("创建入库单失败", "danger");
        return false;
      }

      this.queryParam.datas = this.list.filter((e) => {
        return e.checked;
      });
      if (this.queryParam.datas.length == 0) {
        Utils.presentToastTopWarning("至少选择一种零件", "danger");
        return false;
      } else if (!this.queryParam.applyPersonId) {
        Utils.presentToastTopWarning("请选择申请人", "danger");
        return false;
      }
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "请稍等...",
        duration: 50000,
      });
      await loading.present();
      doSaveDeliveryItem(this.queryParam).then((res) => {
        loading.dismiss();
        if (!res.data.isError) {
          var data = this.queryParam;
          data.datas = res.data.data.resultData;
          this.$router.replace({
            name: "partsofWarehouse",
            params: { queryParam: JSON.stringify(data) },
          });
          Utils.presentToastTopWarning("保存成功", "success");
        } else {
          Utils.presentToastTopWarning(res.data.message, "danger");
        }
      });
    },
    // 选择任务单
    chooseCode(e) {
      this.queryParam.stageCode = e.code;
      this.islist = true;
      this.searchPerson({
        id: e.submitPersonId,
        nameCN: e.submitPerson,
        telephone: e.submitPersonPhone,
      });
      getDeliveryItem({ stageCode: e.code, id: this.queryParam.id }).then(
        (res) => {
          this.islist = false;
          console.log(res);
          this.list = res.data.data.resultData || [];
          this.tableList = res.data.data.resultData || [];
        }
      );
    },
    searchPart(event) {
      this.tableList = this.list.filter((e) => {
        return e.partNameCN.indexOf(this.value) > -1;
      });
    },
    close(item){
      item.checked = false
    }
  },
};
</script>
<style lang="scss" scoped>
// ion-item {
//   --padding-end: 12px;
//   --padding-start: 12px;
//   --background: #fff;
// }
// .required {
//   color: #ff0000;
//   margin-right: 6px;
// }
// ion-textarea {
//   --placeholder-color: #333333;
//   --placeholder-opacity: 0.5;
//   --placeholder-font-weight: bold;
//   font-size: 10px;
//   --padding-start: 10px;
//   padding-left: 10px;
// }
// ion-label {
//   font-size: 12px !important;
//   font-family: PingFang SC, PingFang SC-Regular;
//   font-weight: bold;
//   text-align: left;
//   color: #333333;
// }
.icon-lingbujianxinxi {
  font-size: 48px;
}
.selectBtn {
  width: 60px;
  height: 30px;
}
.title-canvars {
  background-color: #ffffff;
  font-size: px2rem(20);
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.canvars-style {
  margin: 0 auto;
  border: 1px solid #999;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100px;
  background: #fff;
}
ion-button {
  --background: #00529c;
}

.star {
  color: red;
  padding-top: px2rem(10);
}
.root:last-child {
  margin-top: px2rem(15);
}
.root {
  width: calc(100% #{px2rem(58)});
  height: auto;
  margin: px2rem(20) auto;
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: px2rem(20) px2rem(45);
  font-size: #{$default-font-size};
}
.lefttwo {
  width: px2rem(270) !important;
  height: auto !important;
  line-height: px2rem(38) !important;
  // text-align: left;
  color: $color !important;
}
.item {
  .title {
    width: 100% !important;
    font-size: px2rem(28) !important;
    font-weight: 700;
    ion-textarea {
      --placeholder-color: #8e8e8e;
      --placeholder-opacity: 1;
    }
  }
}
.van-tag{
  margin: 10px 10px 0;
}
.uniline {
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  height: px2rem(88);
  display: flex;
  .left {
    width: 30%;
    line-height: px2rem(88);
    text-align: left;
  }
  .right {
    width: 70%;
    height: px2rem(88);
    text-align: right;
    --color: black;
    line-height: px2rem(88);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .iconfont {
      margin-left: px2rem(25);
      font-size: px2rem(12);
      color: #325cab;
    }
    ion-icon {
      margin-top: px2rem(10);
      width: px2rem(26);
      height: px2rem(26);
      color: #325cab;
    }
  }

  .ser {
    // 放大镜、、暂无
    font-size: px2rem(26);
  }
}
.uniline-last {
  border-width: 0;
}
.multi-line {
  margin-top: 1rem;
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  border-width: 0;
  text-align: left;
  .textarea {
    width: calc(100%-#{px2rem(24)+3rem});
    --padding-top: 0;
    margin-left: 3rem;
    --color: black;
  }
}
.moreproject {
  text-align: center;
  font-size: px2rem(24px);
  color: #325cab;
  margin-top: px2rem(20px);
}

.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      .addBorderRight {
        display: inline-block;
        padding-right: px2rem(20);
        margin-right: px2rem(20);
        height: px2rem(36);
        border-right: 2px solid rgba(151, 151, 151, 0.3);
      }
    }
    .item-label {
      width: 60%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
</style>