<template >
  <ion-menu
    menu-id="searchPerson"
    side="end"
    content-id="ProjectMainProjectListOut"
    :swipeGesture="false"
    @ionDidClose="(dataTable = []), (keyword = '')"
  >
    <ion-content class="select-user-dept-team">
      <ion-header class="theme2">
        <ion-toolbar>
          <p slot="start" style="font-size:14px">
            <span class="iconfont icon-renwushaixuan"></span>&nbsp;申请人
          </p>
          <ion-buttons slot="end" class="close">
            <ion-button @click="closeModel">
              <span class="iconfont icon-guanbi"></span>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <div class="meetingPeople">
        <ion-grid>
          <ion-row class="ion-row-flex">
            <ion-col size="12" class="ion-col-common add-text-align-left">
              <div class="searchBox">
                <SearchBar
                  :isAudio="false"
                  theme="1"
                  :searchValue="keyword"
                  @on-change="searchHandler"
                  placeholder="请输入申请人姓名"
                ></SearchBar>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <!-- List of Text Items -->
        <ion-list>
          <ion-item
            v-for="(item, index) in dataTable"
            :key="index"
            class="itemsprojectuser"
            @click="onClose(item)"
          >
            <ion-label>{{ item.nameCN }}</ion-label>
          </ion-item>
        </ion-list>
        <CardLoading
          v-if="dataTable.length <= 0 && islist"
          lodingType="1"
        ></CardLoading>
      </div>
    </ion-content>
  </ion-menu>
</template>
<script>
import { defineComponent } from "vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import { menuController } from "@ionic/vue";
import { getApplyPerson } from "@/api/storeManagement/partsofWarehouse";
import Utils from "../../utils/Utils";
export default defineComponent({
  props: {
    id: "",
  },
  components: {
    CardLoading,
    Header,
    SearchBar,
  },
  data() {
    return {
      dataTable: [],
      islist: false,
      keyword: "",
      timer: null,
    };
  },
  methods: {
    initData() {
      this.islist = true;
      getApplyPerson({
        nameCN: this.keyword,
      }).then((res) => {
        this.islist = false;
        this.dataTable = res.data.data.resultData || [];
      });
    },
    searchHandler(searchParam) {
      var _this = this;
      if (Utils.isInCludeEmoji(searchParam.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }

      this.keyword = searchParam.searchKey;
      console.log(searchParam.searchKey);
      if (this.timer) clearTimeout(_this.timer);
      this.timer = setTimeout(() => {
        if (_this.keyword) {
          _this.initData();
        }
      }, 1000);
    },
    onClose(item) {
      this.$emit("chooseCode", item);
      menuController.close();
    },
    closeModel() {
      menuController.close();
    },
  },
});
</script>